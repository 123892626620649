import { Link } from "react-router-dom";
const Dashboard = () => {

    return (
        <div className="main-container">
        <section>
            <h1>Dashboard</h1>
            <br />
            <p>Under Construction</p>
            <Link to="/Home">Home</Link>
        </section>
        </div>
    )
}

export default Dashboard