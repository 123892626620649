import React from 'react';
import { Link } from 'react-router-dom';

interface SectionProps {
    imageSrc: string;
    title: string;
    text: string;
    link: string;
    imageOnLeft: boolean;
}

const Projects = () => {
    return (
        <div style={styles.container}>
            <Section
                imageSrc="/images/cleaned_chairserver.webp"
                title="This Site"
                text="This is the server this site right here your on now is hosted on. Yes yes I know a chair is not a good place for a server, its temporary. This server is running Proxmox with docker containers for this sites frontend and backend, both of witch are behind nginx proxy manager and Cloudflare for the ssl with a bunch of other stuff like Home Assistant and some game servers."
                link="/Home"
                imageOnLeft={true}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="This Sites Frontend"
                text="The frontend of this site is a react and node.js server using typescript in a docker container."
                link="https://github.com/Caffeinated-Brit/mymanyprojectsfrontend"
                imageOnLeft={false}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="This Sites Backend"
                text="The backend of this site is a node.js and express server using javascript that connects to a sql database in a proxmox container that I use for the sites authorization. The github repository for the backend is private incase I accidentally put sensitive credintials in it."
                link="/Home"
                imageOnLeft={true}
            />
            <Section
                imageSrc="/images/food.webp"
                title="MealFinder"
                text="This is an old project I did for a class that you enter keywords like ingredients or health or diet labels and it connects to an api and displays foods with those tags, note there is limited api access so dont spam it. No github repository"
                link="/MealFinder"
                imageOnLeft={false}
            />
            <Section
                imageSrc="/images/cleaned_bees2.webp"
                title="Ya like Bees?"
                text="<-- My bees. Not really computer science related but thought id share anyway since I like my bees and they are cool."
                link=""
                imageOnLeft={true}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="Tensorfow Object Detection"
                text="Work in Progress."
                link="/"
                imageOnLeft={false}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="Bus Shuttle"
                text="This was a project for cs 420 where we where to replicate a project using MVC and asp.net. The project we where replicating was a privoius years capstone class project that the campus bus drivers and managers actually use which was pretty cool to see when you got on a bus the code you know running. It was a couple month long solo project which I thought turned out well. Sorry for the lack of a readme."
                link="https://github.com/Caffeinated-Brit/Bus_Shuttle"
                imageOnLeft={true}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="Blacksmithing"
                text="Coming Soon."
                link="/"
                imageOnLeft={false}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="Java TCP file sharing"
                text="This was a project I did for cs 316 where you had a server and could connect clients to it using TCP and upload and download files."
                link="https://github.com/Caffeinated-Brit/TCP-File-Service"
                imageOnLeft={true}
            />
            <Section
                imageSrc="/images/logo512.png"
                title="TCP Client Switch Router Emulation"
                text="This was a project for cs 416 in a three person group. It was done in stages but the goal was to create a virtual client server and router so that the client that could send messages to other clients accross virtual switches and virtual routers using real protocols most of them where some what simpilfyed though. I think it was a very good assignment that we learned a lot about all sorts of networking on, the only other assingment that I could say ive learned as much on was the Bus Shuttle project."
                link="https://github.com/Caffeinated-Brit/cs.416.1.project"
                imageOnLeft={false}
            />
        </div>
    );
};

const Section: React.FC<SectionProps> = ({ imageSrc, title, text, link, imageOnLeft }) => {
    return (
        <div
            style={{
                ...styles.section,
                flexDirection: imageOnLeft ? 'row' : 'row-reverse',
            }}
        >
            <img src={imageSrc} alt={title} style={styles.image} />
            <div style={styles.textContainer}>
                <Link to={link} style={{ textDecoration: 'none' }}>
                    <h2 style={styles.sectionTitle}>{title}</h2>
                </Link>
                <p style={styles.text}>{text}</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 127, 0, 1)',
        // backgroundImage: 'url("/public/images/background-image.jpg")'
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px 0',
        padding: '20px',
        backgroundColor: 'rgba(242, 243, 244, 0.6)',
        borderRadius: '10px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
    image: {
        width: '30%',
        borderRadius: '10px',
        margin: '0 20px',
    },
    textContainer: {
        flex: 1,
    },
    sectionTitle: {
        textDecoration: 'underline',
        fontSize: '1.5em',
        color: '#333',
        margin: '0 0 10px 0',
    },
    text: {
        fontSize: '1em',
        color: '#555',
        lineHeight: '1.6',
    },
};

export default Projects;
